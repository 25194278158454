import React from "react"
import SEO from '../components/seo'
import Background from '../components/Background'
import Wrapper from '../components/Wrapper'
import Heading from '../components/Heading'
import Grid from '../components/Grid'
import Menu from '../components/Menu'
import { useStaticQuery, graphql } from 'gatsby'

const items = [
  {
    title: 'Onebed Mattress',
    imageKey: 'onebed',
    href: 'https://www.onebed.com.au',
    info: 'Leading Australian Mattress Company'
  }, 
  { 
    title: 'NLFORYOU',
    imageKey: 'nlforyou',
    href: 'https://nlforyou.eu',
    info: 'Bulgarian Lingerie Company'
  },
  { 
    title: 'Sunday Mattress',
    imageKey: 'sunday',
    href: 'https://sundayrest.com',
    info: 'Leading Indian Mattress Company'
  },
  {
    title: 'Capsico',
    imageKey: 'capsico',
    href: 'https://capsico.hu',
    info: 'Hungarian Seed Production Company'
  },
  {
    title: 'Microgreens BG',
    imageKey: 'microgreens',
    href: 'https://microgreens-bg.com',
    info: 'Bulgarian Microgreens Grower'
  },
  {
    title: 'Sourced',
    imageKey: 'sourced',
    info: 'Bulgarian Food Retail Startup'
  },
  {
    title: 'Building Website',
    imageKey: 'arhont',
    info: 'Bulgarian Architecture Studio'
  }
]



export default () => {
  const imagesData = useStaticQuery(graphql`
    query ProjectImages {
      onebed: file(relativePath: {eq: "onebed.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nlforyou: file(relativePath: {eq: "nlforyou.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      capsico: file(relativePath: {eq: "capsico.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      microgreens: file(relativePath: {eq: "microgreens.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sunday: file(relativePath: {eq: "sun.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      arhont: file(relativePath: {eq: "arh.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sourced: file(relativePath: {eq: "source.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title={ 'Brester - My Projects'}
        description={ 'Brester.tech projects' }
        lang={ 'en' }
      />
      <Background>
        <Wrapper>
          <Heading>Projects</Heading>
          <Grid
            items={ items }
            imagesData= { imagesData }
          />
          <Menu />
        </Wrapper>
      </Background>
    </>
  )
}